import Vue from 'vue'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import App from './App.vue'
import axios from 'axios'
import router from '@/router/index.js'
import store from '@/store/index.js'

Vue.use(ViewUI);

Vue.config.productionTip = false;


let BASE_URL_SERVER = "https://api.chinasrcc.net";
let STATIC_URL_SERVER = "https://www.chinasrcc.net/";

axios.defaults.withCredentials = false;
Vue.prototype.$axios = axios;
Vue.prototype.$url = BASE_URL_SERVER;
Vue.prototype.$static_url = STATIC_URL_SERVER;

Vue.prototype.$fileUrlFac = function(url,pid){
  if(url&&url.indexOf('http')===-1){
    return STATIC_URL_SERVER + url +'?id=' + pid;
  }else{
      return url;
    }
  
}

Vue.prototype.$fileUrlFacWithoutRight = function(url){
  if(url.indexOf('http')===-1){
  return STATIC_URL_SERVER + url;}
  else{
    return url;
  }
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

export {BASE_URL_SERVER}


