<template>
  <div id="app">
    <router-view></router-view>
    <BackTop></BackTop>
  </div>
</template>

<script>
export default {
  name: "app",
  created() {    
  },
  methods: {
  },
};
</script>

<style>
a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.ivu-table .demo-table-info-row td {
  background-color: #fce1e9;
  color: #666;
}

.ivu-table .demo-table-wait-row td{
  background-color: #fdb0b0;
  color: #000;
}

.ivu-table .demo-table-error-row td {
  background-color: #8a0c0c;
  color: #fff;
}

.ivu-table .demo-table-confirm-row td {
  background-color: #fdfdec;
    color: #666;
  
}

.ivu-table .demo-table-limit-row td{
    background-color: #f8d6d2;
  color: #333;
}

.ivu-table .demo-table-danger-row td{
    background-color: #595c3f;
    color:#111;
}
</style>