

export default [
    //注册和登录
    {
        path:"/login",
        name:"login",
        component:() => import('@/components/Account/Login.vue'),
    },
    
    {
        path:"/account",
        name:"home",
        component:() => import('@/components/ManageFrame.vue'),
        children:[
            {
                path:"/center",
                name:"accountCenter",
                components:{
                default:() => import('@/components/Account/AccountCenter.vue'),
            },
            },
        ]
        
    },
    
    //数据后台管理
    {
        path:"",
        name:'manage',
        component:() => import('@/components/ManageFrame.vue'),
        children:[
            {
                path:'index',
                name:'index',
                components:{
                    default:() => import('@/components/Index.vue'),
                    leftBar:() => import('@/components/MenuBar.vue'),
                }
            },
            {
                path:'userManage',
                name:'userManage',
                components:{
                    default:() => import('@/components/DataManage/UserManage.vue'),
                    leftBar:() => import('@/components/MenuBar.vue'),
                }
            },
            {
                path:'roleManage',
                name:'roleManage',
                components:{
                    default:() => import('@/components/DataManage/RoleManage.vue'),
                    leftBar:() => import('@/components/MenuBar.vue'),
                }
            },
            {
                path:'certificateManage',
                name:'certificateManage',
                components:{
                    default:() => import('@/components/DataManage/CertificateManage.vue'),
                    leftBar:() => import('@/components/MenuBar.vue'),
                }
            },
            {
                path:'historyManage',
                name:'historyManage',
                components:{
                    default:() => import('@/components/DataManage/HistoryManage.vue'),
                    leftBar:() => import('@/components/MenuBar.vue'),
                }
            },
            {
                path:'newsManage',
                name:'newsManage',
                components:{
                    default:() => import('@/components/DataManage/NewsManage.vue'),
                    leftBar:() => import('@/components/MenuBar.vue'),
                }
            },
            {
                path:'servicesManage',
                name:'servicesManage',
                components:{
                    default:() => import('@/components/DataManage/ServicesManage.vue'),
                    leftBar:() => import('@/components/MenuBar.vue'),
                }
            },
            {
                path:'industriesManage',
                name:'industriesManage',
                components:{
                    default:() => import('@/components/DataManage/IndustriesManage.vue'),
                    leftBar:() => import('@/components/MenuBar.vue'),
                }
            },
            {
                path:'jobsManage',
                name:'jobsManage',
                components:{
                    default:() => import('@/components/DataManage/JobsManage.vue'),
                    leftBar:() => import('@/components/MenuBar.vue'),
                }
            },
            {
                path:'archvieManage',
                name:'archiveManage',
                components:{
                    default:() => import('@/components/DataManage/ArchiveManage.vue'),
                    leftBar:() => import('@/components/MenuBar.vue'),
                }
            },
            {
                path:'officesManage',
                name:'officesManage',
                components:{
                    default:() => import('@/components/DataManage/OfficesManage.vue'),
                    leftBar:() => import('@/components/MenuBar.vue'),
                }
            },

        ]

    },
]